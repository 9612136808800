import { useState, useContext, useEffect } from 'react';
import {
    Box,
    Button,
    ButtonGroup,
    Grid,
    useTheme
} from '@mui/material';
import { Link, Routes, Route, useLocation } from 'react-router-dom';
import { API_ROUTES } from '../../utils/constants';
import { GetAnyTopicsExist, HttpStatusCode } from '../../utils/types';
import * as ROUTES from '../../utils/routes';
import { GlobalContext } from '../../contexts/GlobalContext';
import PublicationManager from './Publication/PublicationManager';
import NavBar from '../../shared/NavBar';
import { Item } from '../../shared/Item';
import Footer from '../../shared/Footer';
import TopicManager from '../ManagementDashboard/Topic/TopicManager';
import ResearcherManager from './Researcher/ResearcherManager';

type StateData = {
    anyTopicsExist: boolean;
    topicType: string;
}

const initState: StateData = {
    anyTopicsExist: false,
    topicType: ''
}

export default function ManagementDashboard() {
    const [stateData, setStateData] = useState<StateData>(initState);
    const location = useLocation();
    const theme = useTheme();
    const { user } = useContext(GlobalContext);

    const anyTopicsExist = () => {
        const url = API_ROUTES.TOPIC.GET_ANY_TOPICS_EXIST;

        fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        }).then(res => res.json())
            .then((serverResponse: GetAnyTopicsExist) => {
                const { data, statusCode } = serverResponse;
                if (statusCode === HttpStatusCode.OK) {
                    if (data.anyTopicsExist) {
                        const typeToPass = data.academicTopicsExist ? 'academic' : 'practitioner';
                        setStateData({ anyTopicsExist: data.anyTopicsExist, topicType: typeToPass });
                    }
                }
            }).catch((error) => {
                console.error(error);
            })
    }

    useEffect(() => {
        anyTopicsExist();
        return () => {

        }
        // eslint-disable-next-line
    }, [])

    return (
        <Box sx={{ flexGrow: 1 }} className='home'>
            <Grid container marginBottom={6}>
                <Grid item xs={12}>
                    <NavBar />
                </Grid>
                <Grid item xs={12}>
                    <Item sx={{ borderRadius: 0, backgroundColor: theme.palette.primaryPalette.darkColor, color: 'whitesmoke' }}>
                        <h1 className='h1'>
                            {user.researcherName}
                        </h1>
                    </Item>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    {user &&
                        <Box>
                            <Link to='/profile'>
                                <Button
                                    sx={{margin: 4, backgroundColor: theme.palette.primaryPalette.darkColor, color: 'whitesmoke', ":hover": { backgroundColor: theme.palette.primaryPalette.blueColor}}}
                                >
                                    Profile
                                </Button>
                            </Link>
                        </Box>
                    }
                    {location.pathname !== ROUTES.MANAGEMENT_DASHBOARD &&
                        <Box>
                            <Link to={ROUTES.MANAGEMENT_DASHBOARD}>
                                <Button
                                    sx={{margin: 4, backgroundColor: theme.palette.primaryPalette.darkColor, color: 'whitesmoke', ":hover": { backgroundColor: theme.palette.primaryPalette.blueColor}}}
                                >
                                    Dashboard
                                </Button>
                            </Link>
                        </Box>
                    }
                </Box>
                <Grid container item sx={{marginBottom: 4.5}} textAlign={'center'} alignItems={'center'} justifyContent={'space-around'}>
                    <ButtonGroup>
                        {(user.isAdmin && !location.pathname.includes(ROUTES.RESEARCHER_MANAGEMENT)) &&
                            <Link to={ROUTES.RESEARCHER_MANAGEMENT}>
                                <Button sx={{margin: 0.5, backgroundColor: theme.palette.primaryPalette.darkColor, color: 'whitesmoke', ":hover": { backgroundColor: theme.palette.primaryPalette.blueColor}}}>
                                    Researchers
                                </Button>
                            </Link>
                        }
                        {!location.pathname.includes(ROUTES.TOPIC_MANAGEMENT) &&
                            <Link to={ROUTES.TOPIC_MANAGEMENT}>
                                <Button sx={{ margin: 0.5, backgroundColor: theme.palette.primaryPalette.darkColor, color: 'whitesmoke', ":hover": { backgroundColor: theme.palette.primaryPalette.blueColor}}}>
                                    Topics
                                </Button>
                            </Link>
                        }
                        {(stateData.anyTopicsExist && !location.pathname.includes(ROUTES.PUBLICATION_MANAGEMENT)) &&
                            <Link to={ROUTES.PUBLICATION_MANAGEMENT} state={{ margin: 0.5, topicType: stateData.topicType }}>
                                <Button sx={{ margin: 0.5, backgroundColor: theme.palette.primaryPalette.darkColor, color: 'whitesmoke', ":hover": { backgroundColor: theme.palette.primaryPalette.blueColor}}}>
                                    Publications
                                </Button>
                            </Link>
                        }
                    </ButtonGroup>
                    <Routes>
                        <Route path={ROUTES.RESEARCHER_MANAGEMENT} element={<ResearcherManager />} />
                        <Route path={ROUTES.TOPIC_MANAGEMENT} element={<TopicManager />} />
                        <Route path={ROUTES.PUBLICATION_MANAGEMENT} element={<PublicationManager />} />
                    </Routes>
                </Grid>
            </Grid>
            <Footer />
        </Box>
    );
}
