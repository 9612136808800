export const MEDIA_QUERY_CONSTRAINTS = {
  MIN_WIDTH_SMALL: '(min-width: 600px)',
  MIN_WIDTH_MEDIUM: '(min-width: 900px)',
  MIN_WIDTH_LARGE: '(min-width: 1200px)',
  MIN_WIDTH_EXTRA_LARGE: '(min-width: 1536px)',
}

export const joditConfig = {
  buttons: [
    'Bold', 'Italic','Underline', 'Strikethrough' ,'|', 'ul', 'ol', '|', 'fontsize', 'lineHeight', 'hr', 'table',
    '---', 'image', '|', 'link',
    '\n', 'indent', 'outdent', 'align', '|', 'spellcheck', 'brush'
  ]
}


const API_BASE_URL = 'https://0gu9w1so4k.execute-api.us-east-2.amazonaws.com/prod';

const ENDPOINTS = {
  AUTH: {
    REGISTER: 'Auth/register',
    LOGIN: 'Auth/login',
    LOGOUT: 'Auth/logout',
    REFRESH_TOKEN: 'Auth/refresh-token',
    RESET_PASSWORD: 'Auth/reset-password'
  },
  EMAIL: {
    GET_INVOLVED: 'Email/get-involved',
    CONTACT_AUTHOR: 'Email/contact-author',
    RESET_PASSWORD: 'Email/reset-password',
    INVITE_USER: 'Email/invite-user'
  },
  NEWS: {
    GET_LATEST_NEWS: 'News/get-latest-news',
    ADD_NEWS: 'News/add-news',
    DELETE_NEWS_ITEM_BY_ID: 'News/delete-news-item-by-id',
    UPDATE_NEWS_ITEM: 'News/update-news-item'
  },
  PUBLICATION: {
    CREATE_PUBLICATION: 'Publication/create-publication',
    GET_PUBLICATIONS_BY_CATEGORY_AND_TOPIC_ID: 'Publication/get-publications-by-category-and-topic-id',
    GET_PUBLICATIONS_SORTED_BY_TYPE: 'Publication/get-publications-sorted-by-type',
    UPDATE_PUBLICATION: 'Publication/update-publication',
    UPDATE_PUBLICATION_URL: 'Publication/update-publication-url',
    DELETE_PUBLICATION: 'Publication/delete-publication',
    PUBLISH_PUBLICATION: 'Publication/publish-publication'
  },
  RESEARCHER: {
    ADMIN_CREATE_RESEARCHER: 'Researcher/admin-create-researcher',
    ADMIN_DELETE_RESEARCHER: 'Researcher/admin-delete-researcher',
    ADMIN_UPDATE_RESEARCHER: 'Researcher/admin-update-researcher',
    APPROVE_RESEARCHER_PROFILE_BY_ID: 'Researcher/approve-researcher-profile-by-id',
    GET_RESEARCHER_BY_ID:'Researcher/get-researcher-by-id',
    GET_ALL_IDS_NAMES: 'Researcher/all-ids-names',
    UPDATE_RESEARCHER: 'Researcher/update-researcher',
    UPDATE_PROFILE_PICTURE: 'Researcher/update-profile-picture',
    GET_ADMIN_DASHBOARD_RESEARCHERS: 'Researcher/admin-dashboard-researchers',
    GET_APPROVED_RESEARCHERS: 'Researcher/get-approved-researchers'
  },
  MEDIA: {
    GET_TOPIC_PICTURE_UPLOAD_URL: "Media/topic-picture-upload-url",
    GET_ADMIN_RESEARCHER_PICTURE_UPLOAD_URL: "Media/admin-researcher-profile-picture-url",
    GET_PROFILE_PICTURE_UPLOAD_URL: 'Media/get-profile-picture-upload-url',
    GET_PUBLICATION_UPLOAD_URL: 'Media/get-publication-upload-url',
    DELETE_TOPIC_PICTURE: 'Media/delete-topic-picture',
    DELETE_PUBLICATION: 'Media/delete-publication'
  },
  TOPIC: {
    ASSIGN_AUTHOR: 'Topic/assign-author',
    CREATE_TOPIC: 'Topic/create-topic',
    GET_ALL_TOPICS: 'Topic/get-all-topics',
    GET_ANY_TOPICS_EXIST: 'Topic/any-topics-exist',
    GET_SCHOLARSHIP_SUMMARIES: 'Topic/get-nav-topics',
    GET_TOPIC_DETAILS_BY_ID: 'Topic/get-topic-details-by-id',
    GET_TOPICS_BY_TYPE: 'Topic/get-topics-by-type',
    UPDATE_TOPIC: 'Topic/update-topic-details',
    UPDATE_BASE_TOPIC: 'Topic/update-base-topic',
    PUBLIC_TOPIC_PAGE: 'Topic/publish-topic-page',
    DELETE_TOPIC_BY_ID: 'Topic/delete-topic-by-id'
  },
  TOPIC_CATEGORY: {
    GET_PUBLICATIONS_PAGE: 'TopicCategory/get-publications-page',
    GET_SORTED_TOPICS_AND_CATEGORIES: 'TopicCategory/sorted-topics-and-categories',
    GET_SORTED_TOPIC_CATEGORIES_BY_TOPIC_IDS: 'TopicCategory/get-sorted-topic-category-by-topic-ids'
  }
};

const routes = {
  AUTH: {
    ACCOUNT_REGISTRATION: `${API_BASE_URL}/${ENDPOINTS.AUTH.REGISTER}`,
    ACCOUNT_LOGIN: `${API_BASE_URL}/${ENDPOINTS.AUTH.LOGIN}`,
    ACCOUNT_LOGOUT: `${API_BASE_URL}/${ENDPOINTS.AUTH.LOGOUT}`,
    REFRESH_TOKEN: `${API_BASE_URL}/${ENDPOINTS.AUTH.REFRESH_TOKEN}`,
    RESET_PASSWORD: `${API_BASE_URL}/${ENDPOINTS.AUTH.RESET_PASSWORD}`
  },
  EMAIL: {
    GET_INVOLVED: `${API_BASE_URL}/${ENDPOINTS.EMAIL.GET_INVOLVED}`,
    CONTACT_AUTHOR: `${API_BASE_URL}/${ENDPOINTS.EMAIL.CONTACT_AUTHOR}`,
    RESET_PASSWORD: `${API_BASE_URL}/${ENDPOINTS.EMAIL.RESET_PASSWORD}`,
    INVITE_USER: `${API_BASE_URL}/${ENDPOINTS.EMAIL.INVITE_USER}`
  },
  NEWS: {
    GET_LATEST_NEWS: `${API_BASE_URL}/${ENDPOINTS.NEWS.GET_LATEST_NEWS}`,
    ADD_NEWS: `${API_BASE_URL}/${ENDPOINTS.NEWS.ADD_NEWS}`,
    DELETE_NEWS_ITEM_BY_ID: `${API_BASE_URL}/${ENDPOINTS.NEWS.DELETE_NEWS_ITEM_BY_ID}`,
    UPDATE_NEWS_ITEM: `${API_BASE_URL}/${ENDPOINTS.NEWS.UPDATE_NEWS_ITEM}`
  },
  PUBLICATION: {
    CREATE_PUBLICATION: `${API_BASE_URL}/${ENDPOINTS.PUBLICATION.CREATE_PUBLICATION}`,
    GET_PUBLICATIONS_BY_CATEGORY_AND_TOPIC_ID: `${API_BASE_URL}/${ENDPOINTS.PUBLICATION.GET_PUBLICATIONS_BY_CATEGORY_AND_TOPIC_ID}`,
    GET_PUBLICATIONS_SORTED_BY_TYPE: `${API_BASE_URL}/${ENDPOINTS.PUBLICATION.GET_PUBLICATIONS_SORTED_BY_TYPE}`,
    UPDATE_PUBLICATION: `${API_BASE_URL}/${ENDPOINTS.PUBLICATION.UPDATE_PUBLICATION}`,
    DELETE_PUBLICATION: `${API_BASE_URL}/${ENDPOINTS.PUBLICATION.DELETE_PUBLICATION}`,
    PUBLISH_PUBLICATION: `${API_BASE_URL}/${ENDPOINTS.PUBLICATION.PUBLISH_PUBLICATION}`
  },
  RESEARCHER: {
    ADMIN_CREATE_RESEARCHER: `${API_BASE_URL}/${ENDPOINTS.RESEARCHER.ADMIN_CREATE_RESEARCHER}`,
    ADMIN_DELETE_RESEARCHER: `${API_BASE_URL}/${ENDPOINTS.RESEARCHER.ADMIN_DELETE_RESEARCHER}`,
    ADMIN_UPDATE_RESEARCHER: `${API_BASE_URL}/${ENDPOINTS.RESEARCHER.ADMIN_UPDATE_RESEARCHER}`,
    APPROVE_RESEARCHER_PROFILE_BY_ID: `${API_BASE_URL}/${ENDPOINTS.RESEARCHER.APPROVE_RESEARCHER_PROFILE_BY_ID}`,
    GET_ALL_IDS_NAME: `${API_BASE_URL}/${ENDPOINTS.RESEARCHER.GET_ALL_IDS_NAMES}`,
    GET_RESEARCHER_BY_ID: `${API_BASE_URL}/${ENDPOINTS.RESEARCHER.GET_RESEARCHER_BY_ID}`,
    UPDATE_RESEARCHER: `${API_BASE_URL}/${ENDPOINTS.RESEARCHER.UPDATE_RESEARCHER}`,
    GET_ADMIN_DASHBOARD_RESEARCHERS: `${API_BASE_URL}/${ENDPOINTS.RESEARCHER.GET_ADMIN_DASHBOARD_RESEARCHERS}`,
    GET_APPROVED_RESEARCHERS: `${API_BASE_URL}/${ENDPOINTS.RESEARCHER.GET_APPROVED_RESEARCHERS}`
  },
  MEDIA: {
    GET_TOPIC_PICTURE_UPLOAD_URL: `${API_BASE_URL}/${ENDPOINTS.MEDIA.GET_TOPIC_PICTURE_UPLOAD_URL}`,
    GET_ADMIN_RESEARCHER_PICTURE_UPLOAD_URL: `${API_BASE_URL}/${ENDPOINTS.MEDIA.GET_ADMIN_RESEARCHER_PICTURE_UPLOAD_URL}`,
    GET_PROFILE_PICTURE_UPLOAD_URL: `${API_BASE_URL}/${ENDPOINTS.MEDIA.GET_PROFILE_PICTURE_UPLOAD_URL}`,
    GET_PUBLICATION_UPLOAD_URL: `${API_BASE_URL}/${ENDPOINTS.MEDIA.GET_PUBLICATION_UPLOAD_URL}`,
    DELETE_TOPIC_PICTURE: `${API_BASE_URL}/${ENDPOINTS.MEDIA.DELETE_TOPIC_PICTURE}`,
    DELETE_PUBLICATION: `${API_BASE_URL}/${ENDPOINTS.MEDIA.DELETE_PUBLICATION}`
  },
  TOPIC: {
    ASSIGN_AUTHOR: `${API_BASE_URL}/${ENDPOINTS.TOPIC.ASSIGN_AUTHOR}`,
    GET_SCHOLARSHIP_SUMMARIES: `${API_BASE_URL}/${ENDPOINTS.TOPIC.GET_SCHOLARSHIP_SUMMARIES}`,
    GET_ANY_TOPICS_EXIST: `${API_BASE_URL}/${ENDPOINTS.TOPIC.GET_ANY_TOPICS_EXIST}`,
    GET_TOPIC_DETAILS_BY_ID: `${API_BASE_URL}/${ENDPOINTS.TOPIC.GET_TOPIC_DETAILS_BY_ID}`,
    GET_TOPICS_BY_TYPE: `${API_BASE_URL}/${ENDPOINTS.TOPIC.GET_TOPICS_BY_TYPE}`,
    CREATE_TOPIC: `${API_BASE_URL}/${ENDPOINTS.TOPIC.CREATE_TOPIC}`,
    UPDATE_TOPIC: `${API_BASE_URL}/${ENDPOINTS.TOPIC.UPDATE_TOPIC}`,
    UPDATE_BASE_TOPIC: `${API_BASE_URL}/${ENDPOINTS.TOPIC.UPDATE_BASE_TOPIC}`,
    DELETE_TOPIC_BY_ID: `${API_BASE_URL}/${ENDPOINTS.TOPIC.DELETE_TOPIC_BY_ID}`,
    PUBLISH_TOPIC_PAGE: `${API_BASE_URL}/${ENDPOINTS.TOPIC.PUBLIC_TOPIC_PAGE}`
  },
  TOPIC_CATEGORY: {
    GET_PUBLICATIONS_PAGE: `${API_BASE_URL}/${ENDPOINTS.TOPIC_CATEGORY.GET_PUBLICATIONS_PAGE}`,
    GET_SORTED_TOPICS_AND_CATEGORIES: `${API_BASE_URL}/${ENDPOINTS.TOPIC_CATEGORY.GET_SORTED_TOPICS_AND_CATEGORIES}`,
    GET_SORTED_TOPIC_CATEGORIES_BY_TOPIC_ID: `${API_BASE_URL}/${ENDPOINTS.TOPIC_CATEGORY.GET_SORTED_TOPIC_CATEGORIES_BY_TOPIC_IDS}`
  }
};

export const API_ROUTES = routes;
